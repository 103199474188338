/*!--------------------------------------------------------------
# main.scss
#
# Sass file for Options-admin template.
# Author: SaltTechno
#
# This is compressed CSS file. You get uncompressed version of
# this file and all source scss files with download.
#
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# [TABLE OF CONTENTS]
#
# 1. VARIABLES & MIXINS
# 2. TOP NAVABR
# 3. SIDEBARS
# 4. LAYOUT
# 5. PANELS
# 6. TABS
# 7. LABELS & BADGES
# 8. SECTIONS
# 9. BUTTONS
# 10. MODALS
# 11. DASHBOARD STATS
# 12. NOTIFICATIONS
# 13. PAGINATION
# 14. FORMS
# 15. ERROR PAGES
# 16. PRICING
# 17. LOGIN
# 18. DROPZONE
# 19. BOOTSTRAP SWITCH
# 20. JQUERY STEPS
# 21. DATATABLES
# 22. MISCELLANEOUS
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# 1. VARIABLES & MIXINS
--------------------------------------------------------------*/
@import 'https://fonts.googleapis.com/css?family=Poppins:300,400,600';
html, body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #494949;
  font-size: 12px;
  line-height: 1.75em;
}

@media (min-width: 600px) {
  html, body {
    font-size: calc( 12px + (14 - 12) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  html, body {
    font-size: 14px;
  }
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
}

p, blockquote, pre,
address,
dl, ol, ul,
table {
  margin-bottom: 1.75em;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #292929;
  clear: both;
}

h1, .h1 {
  font-size: 27.8543898685px;
  margin-top: 0.9424008253em;
  line-height: 1.1308809903em;
  margin-bottom: 0.1884801651em;
}

@media (min-width: 600px) {
  h1, .h1 {
    font-size: calc( 27.8543898685px + (32.4967881799 - 27.8543898685) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  h1, .h1 {
    font-size: 32.4967881799px;
    margin-top: 0.9424008253em;
    line-height: 1.1308809903em;
    margin-bottom: 0.1884801651em;
  }
}

h2, .h2 {
  font-size: 23.5370033519px;
  margin-top: 1.1152651681em;
  line-height: 1.3383182017em;
  margin-bottom: 0.2514831227em;
}

@media (min-width: 600px) {
  h2, .h2 {
    font-size: calc( 23.5370033519px + (27.4598372439 - 23.5370033519) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  h2, .h2 {
    font-size: 27.4598372439px;
    margin-top: 1.1152651681em;
    line-height: 1.3383182017em;
    margin-bottom: 0.2474216814em;
  }
}

h3, .h3 {
  font-size: 19.888804939px;
  margin-top: 1.3198379732em;
  line-height: 1.5838055678em;
  margin-bottom: 0.3037841038em;
}

@media (min-width: 600px) {
  h3, .h3 {
    font-size: calc( 19.888804939px + (23.2036057621 - 19.888804939) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  h3, .h3 {
    font-size: 23.2036057621px;
    margin-top: 1.3198379732em;
    line-height: 1.5838055678em;
    margin-bottom: 0.2980960311em;
  }
}

h4, .h4 {
  font-size: 16.8060715286px;
  margin-top: 1.5619355157em;
  line-height: 1.8743226189em;
  margin-bottom: 0.3681503615em;
}

@media (min-width: 600px) {
  h4, .h4 {
    font-size: calc( 16.8060715286px + (19.60708345 - 16.8060715286) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  h4, .h4 {
    font-size: 19.60708345px;
    margin-top: 1.5619355157em;
    line-height: 1.8743226189em;
    margin-bottom: 0.3601841818em;
  }
}

h5, .h5 {
  font-size: 14.2011569368px;
  margin-top: 1.8484409486em;
  line-height: 2.2181291384em;
  margin-bottom: 0.3696881897em;
}

@media (min-width: 600px) {
  h5, .h5 {
    font-size: calc( 14.2011569368px + (16.5680164262 - 14.2011569368) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  h5, .h5 {
    font-size: 16.5680164262px;
    margin-top: 1.8484409486em;
    line-height: 2.2181291384em;
    margin-bottom: 0.3696881897em;
  }
}

h6, .h6 {
  font-size: 12px;
  margin-top: 2.1875em;
  line-height: 2.625em;
  margin-bottom: 0.5104166667em;
}

@media (min-width: 600px) {
  h6, .h6 {
    font-size: calc( 12px + (14 - 12) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  h6, .h6 {
    font-size: 14px;
    margin-top: 2.1875em;
    line-height: 2.625em;
    margin-bottom: 0.4739583333em;
  }
}

blockquote {
  font-style: italic;
}

blockquote cite {
  font-style: normal;
}

pre {
  padding: 0.875em;
  margin-bottom: 1.75em;
}

pre code {
  padding: 0;
}

code {
  font-family: "Courier New", "Courier", "Lucida Sans Typewriter", "Lucida Typewriter", "monospace";
  padding: 0.0875em 0.2625em;
  line-height: 0;
}

big, small, sub, sup {
  line-height: 0;
}

abbr, acronym {
  border-bottom: 1px dotted currentColor;
  cursor: help;
}

address {
  font-style: normal;
}

dt {
  color: #292929;
  font-weight: bold;
}

ul {
  padding-left: 1.1em;
}

ol {
  padding-left: 1.4em;
}

fieldset {
  padding: 0.875em 1.75em 1.75em;
  border-width: 1px;
  border-style: solid;
  max-width: 100%;
  margin-bottom: 1.875em;
}

@media (min-width: 1140px) {
  fieldset {
    margin-bottom: 2.03125em;
  }
}

fieldset button, fieldset input[type="submit"] {
  margin-bottom: 0;
}

legend {
  color: #292929;
  font-weight: bold;
}

input[type="text"], input[type="email"], input[type="password"], input[type="date"], input[type="datetime-local"], input[type="color"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="url"], input[type="search"], textarea {
  display: block;
  max-width: 100%;
  padding: 0.4375em;
  font-size: 12px;
  margin-bottom: 1.3125em;
}

@media (min-width: 600px) {
  input[type="text"], input[type="email"], input[type="password"], input[type="date"], input[type="datetime-local"], input[type="color"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="url"], input[type="search"], textarea {
    font-size: calc( 12px + (14 - 12) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  input[type="text"], input[type="email"], input[type="password"], input[type="date"], input[type="datetime-local"], input[type="color"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="url"], input[type="search"], textarea {
    font-size: 14px;
    margin-bottom: 0.546875em;
  }
}

input[type="submit"], button {
  /* display: block; */
  cursor: pointer;
  font-size: 12px;
  padding: 0.4375em 1.75em;
  margin-bottom: 0;
}

@media (min-width: 600px) {
  input[type="submit"], button {
    font-size: calc( 12px + (14 - 12) * ((100vw - 600px) / (1140 - 600)));
  }
}

@media (min-width: 1140px) {
  input[type="submit"], button {
    font-size: 14px;
    margin-bottom: 0;
  }
}

label {
  display: block;
  padding-bottom: 0.21875em;
  margin-bottom: -0.125em;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 2.375em;
}

@media (min-width: 1140px) {
  table {
    margin-bottom: 2.125em;
  }
}

th {
  text-align: left;
  color: #292929;
  padding: 0.21875em 0.875em;
}

@media (min-width: 1140px) {
  th {
    padding: 0.4375em 0.875em;
  }
}

td {
  padding: 0.875em;
  padding: 0.21875em 0.875em;
}

@media (min-width: 1140px) {
  td {
    padding: 0.4375em 0.875em;
  }
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #f2f2f2;
  color: #494949;
  text-rendering: optimizeLegibility !important;
  letter-spacing: 0.5px;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  box-shadow: none;
}

body::-webkit-scrollbar-thumb {
  background: rgba(30, 30, 30, 0.8);
  box-shadow: none;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(210, 210, 210, 0.4);
}

::-moz-selection {
  background-color: #292929;
  color: #fff;
}

::selection {
  background-color: #292929;
  color: #fff;
}

a {
  outline: none !important;
  text-decoration: none !important;
  color: #494949;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

code:not(.language-html) {
  font-weight: 600;
}

/*--------------------------------------------------------------
# MIXIN TO CORRECT NAVBAR WITH OUR COLOR PALETTE
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# BACKGROUND COLOR PALLETES
--------------------------------------------------------------*/
.bg-primary {
  background-color: #3498db;
  border-color: #3498db;
  color: #fff !important;
}

.bg-primary h1, .bg-primary h2, .bg-primary h3, .bg-primary h4, .bg-primary h5, .bg-primary h6 {
  color: #fff;
}

.bg-primary .nav .open > a, .bg-primary .nav .open > a:focus, .bg-primary .nav .open > a:hover {
  background-color: #258cd1;
  color: #fff !important;
}

.bg-primary .nav > li > a:focus, .bg-primary .nav > li > a:hover {
  background-color: #258cd1;
  color: #fff !important;
}

.bg-primary .navbar-nav > li > a {
  color: #fff;
}

.bg-primary.small-nav:hover .child-nav {
  background-color: #3498db;
}

.bg-primary.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #3498db;
}

.bg-primary-300 {
  background-color: #54a8e1;
  border-color: #54a8e1;
  color: #fff !important;
}

.bg-primary-300 h1, .bg-primary-300 h2, .bg-primary-300 h3, .bg-primary-300 h4, .bg-primary-300 h5, .bg-primary-300 h6 {
  color: #fff;
}

.bg-primary-300 .nav .open > a, .bg-primary-300 .nav .open > a:focus, .bg-primary-300 .nav .open > a:hover {
  background-color: #3f9ddd;
  color: #fff !important;
}

.bg-primary-300 .nav > li > a:focus, .bg-primary-300 .nav > li > a:hover {
  background-color: #3f9ddd;
  color: #fff !important;
}

.bg-primary-300 .navbar-nav > li > a {
  color: #fff;
}

.bg-primary-300.small-nav:hover .child-nav {
  background-color: #54a8e1;
}

.bg-primary-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #54a8e1;
}

.bg-primary-100 {
  background-color: #75b9e7;
  border-color: #75b9e7;
  color: #fff !important;
}

.bg-primary-100 h1, .bg-primary-100 h2, .bg-primary-100 h3, .bg-primary-100 h4, .bg-primary-100 h5, .bg-primary-100 h6 {
  color: #fff;
}

.bg-primary-100 .nav .open > a, .bg-primary-100 .nav .open > a:focus, .bg-primary-100 .nav .open > a:hover {
  background-color: #5faee3;
  color: #fff !important;
}

.bg-primary-100 .nav > li > a:focus, .bg-primary-100 .nav > li > a:hover {
  background-color: #5faee3;
  color: #fff !important;
}

.bg-primary-100 .navbar-nav > li > a {
  color: #fff;
}

.bg-primary-100.small-nav:hover .child-nav {
  background-color: #75b9e7;
}

.bg-primary-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #75b9e7;
}

.bg-primary-500 {
  background-color: #258cd1;
  border-color: #258cd1;
  color: #fff !important;
}

.bg-primary-500 h1, .bg-primary-500 h2, .bg-primary-500 h3, .bg-primary-500 h4, .bg-primary-500 h5, .bg-primary-500 h6 {
  color: #fff;
}

.bg-primary-500 .nav .open > a, .bg-primary-500 .nav .open > a:focus, .bg-primary-500 .nav .open > a:hover {
  background-color: #217dbb;
  color: #fff !important;
}

.bg-primary-500 .nav > li > a:focus, .bg-primary-500 .nav > li > a:hover {
  background-color: #217dbb;
  color: #fff !important;
}

.bg-primary-500 .navbar-nav > li > a {
  color: #fff;
}

.bg-primary-500.small-nav:hover .child-nav {
  background-color: #258cd1;
}

.bg-primary-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #258cd1;
}

.bg-primary-600 {
  background-color: #217dbb;
  border-color: #217dbb;
  color: #fff !important;
}

.bg-primary-600 h1, .bg-primary-600 h2, .bg-primary-600 h3, .bg-primary-600 h4, .bg-primary-600 h5, .bg-primary-600 h6 {
  color: #fff;
}

.bg-primary-600 .nav .open > a, .bg-primary-600 .nav .open > a:focus, .bg-primary-600 .nav .open > a:hover {
  background-color: #1d6fa5;
  color: #fff !important;
}

.bg-primary-600 .nav > li > a:focus, .bg-primary-600 .nav > li > a:hover {
  background-color: #1d6fa5;
  color: #fff !important;
}

.bg-primary-600 .navbar-nav > li > a {
  color: #fff;
}

.bg-primary-600.small-nav:hover .child-nav {
  background-color: #217dbb;
}

.bg-primary-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #217dbb;
}

.bg-primary-700 {
  background-color: #1d6fa5;
  border-color: #1d6fa5;
  color: #fff !important;
}

.bg-primary-700 h1, .bg-primary-700 h2, .bg-primary-700 h3, .bg-primary-700 h4, .bg-primary-700 h5, .bg-primary-700 h6 {
  color: #fff;
}

.bg-primary-700 .nav .open > a, .bg-primary-700 .nav .open > a:focus, .bg-primary-700 .nav .open > a:hover {
  background-color: #196090;
  color: #fff !important;
}

.bg-primary-700 .nav > li > a:focus, .bg-primary-700 .nav > li > a:hover {
  background-color: #196090;
  color: #fff !important;
}

.bg-primary-700 .navbar-nav > li > a {
  color: #fff;
}

.bg-primary-700.small-nav:hover .child-nav {
  background-color: #1d6fa5;
}

.bg-primary-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #1d6fa5;
}

.bg-danger {
  background-color: #e74c3c;
  border-color: #e74c3c;
  color: #fff !important;
}

.bg-danger h1, .bg-danger h2, .bg-danger h3, .bg-danger h4, .bg-danger h5, .bg-danger h6 {
  color: #fff;
}

.bg-danger .nav .open > a, .bg-danger .nav .open > a:focus, .bg-danger .nav .open > a:hover {
  background-color: #e43725;
  color: #fff !important;
}

.bg-danger .nav > li > a:focus, .bg-danger .nav > li > a:hover {
  background-color: #e43725;
  color: #fff !important;
}

.bg-danger .navbar-nav > li > a {
  color: #fff;
}

.bg-danger.small-nav:hover .child-nav {
  background-color: #e74c3c;
}

.bg-danger.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #e74c3c;
}

.bg-danger-300 {
  background-color: #eb6b5e;
  border-color: #eb6b5e;
  color: #fff !important;
}

.bg-danger-300 h1, .bg-danger-300 h2, .bg-danger-300 h3, .bg-danger-300 h4, .bg-danger-300 h5, .bg-danger-300 h6 {
  color: #fff;
}

.bg-danger-300 .nav .open > a, .bg-danger-300 .nav .open > a:focus, .bg-danger-300 .nav .open > a:hover {
  background-color: #e85647;
  color: #fff !important;
}

.bg-danger-300 .nav > li > a:focus, .bg-danger-300 .nav > li > a:hover {
  background-color: #e85647;
  color: #fff !important;
}

.bg-danger-300 .navbar-nav > li > a {
  color: #fff;
}

.bg-danger-300.small-nav:hover .child-nav {
  background-color: #eb6b5e;
}

.bg-danger-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #eb6b5e;
}

.bg-danger-100 {
  background-color: #ef8b80;
  border-color: #ef8b80;
  color: #fff !important;
}

.bg-danger-100 h1, .bg-danger-100 h2, .bg-danger-100 h3, .bg-danger-100 h4, .bg-danger-100 h5, .bg-danger-100 h6 {
  color: #fff;
}

.bg-danger-100 .nav .open > a, .bg-danger-100 .nav .open > a:focus, .bg-danger-100 .nav .open > a:hover {
  background-color: #ed7669;
  color: #fff !important;
}

.bg-danger-100 .nav > li > a:focus, .bg-danger-100 .nav > li > a:hover {
  background-color: #ed7669;
  color: #fff !important;
}

.bg-danger-100 .navbar-nav > li > a {
  color: #fff;
}

.bg-danger-100.small-nav:hover .child-nav {
  background-color: #ef8b80;
}

.bg-danger-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #ef8b80;
}

.bg-danger-500 {
  background-color: #e43725;
  border-color: #e43725;
  color: #fff !important;
}

.bg-danger-500 h1, .bg-danger-500 h2, .bg-danger-500 h3, .bg-danger-500 h4, .bg-danger-500 h5, .bg-danger-500 h6 {
  color: #fff;
}

.bg-danger-500 .nav .open > a, .bg-danger-500 .nav .open > a:focus, .bg-danger-500 .nav .open > a:hover {
  background-color: #d62c1a;
  color: #fff !important;
}

.bg-danger-500 .nav > li > a:focus, .bg-danger-500 .nav > li > a:hover {
  background-color: #d62c1a;
  color: #fff !important;
}

.bg-danger-500 .navbar-nav > li > a {
  color: #fff;
}

.bg-danger-500.small-nav:hover .child-nav {
  background-color: #e43725;
}

.bg-danger-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #e43725;
}

.bg-danger-600 {
  background-color: #d62c1a;
  border-color: #d62c1a;
  color: #fff !important;
}

.bg-danger-600 h1, .bg-danger-600 h2, .bg-danger-600 h3, .bg-danger-600 h4, .bg-danger-600 h5, .bg-danger-600 h6 {
  color: #fff;
}

.bg-danger-600 .nav .open > a, .bg-danger-600 .nav .open > a:focus, .bg-danger-600 .nav .open > a:hover {
  background-color: #bf2718;
  color: #fff !important;
}

.bg-danger-600 .nav > li > a:focus, .bg-danger-600 .nav > li > a:hover {
  background-color: #bf2718;
  color: #fff !important;
}

.bg-danger-600 .navbar-nav > li > a {
  color: #fff;
}

.bg-danger-600.small-nav:hover .child-nav {
  background-color: #d62c1a;
}

.bg-danger-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #d62c1a;
}

.bg-danger-700 {
  background-color: #bf2718;
  border-color: #bf2718;
  color: #fff !important;
}

.bg-danger-700 h1, .bg-danger-700 h2, .bg-danger-700 h3, .bg-danger-700 h4, .bg-danger-700 h5, .bg-danger-700 h6 {
  color: #fff;
}

.bg-danger-700 .nav .open > a, .bg-danger-700 .nav .open > a:focus, .bg-danger-700 .nav .open > a:hover {
  background-color: #a82315;
  color: #fff !important;
}

.bg-danger-700 .nav > li > a:focus, .bg-danger-700 .nav > li > a:hover {
  background-color: #a82315;
  color: #fff !important;
}

.bg-danger-700 .navbar-nav > li > a {
  color: #fff;
}

.bg-danger-700.small-nav:hover .child-nav {
  background-color: #bf2718;
}

.bg-danger-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #bf2718;
}

.bg-success {
  background-color: #27ae60;
  border-color: #27ae60;
  color: #fff !important;
}

.bg-success h1, .bg-success h2, .bg-success h3, .bg-success h4, .bg-success h5, .bg-success h6 {
  color: #fff;
}

.bg-success .nav .open > a, .bg-success .nav .open > a:focus, .bg-success .nav .open > a:hover {
  background-color: #229955;
  color: #fff !important;
}

.bg-success .nav > li > a:focus, .bg-success .nav > li > a:hover {
  background-color: #229955;
  color: #fff !important;
}

.bg-success .navbar-nav > li > a {
  color: #fff;
}

.bg-success.small-nav:hover .child-nav {
  background-color: #27ae60;
}

.bg-success.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #27ae60;
}

.bg-success-300 {
  background-color: #2ecd71;
  border-color: #2ecd71;
  color: #fff !important;
}

.bg-success-300 h1, .bg-success-300 h2, .bg-success-300 h3, .bg-success-300 h4, .bg-success-300 h5, .bg-success-300 h6 {
  color: #fff;
}

.bg-success-300 .nav .open > a, .bg-success-300 .nav .open > a:focus, .bg-success-300 .nav .open > a:hover {
  background-color: #29b866;
  color: #fff !important;
}

.bg-success-300 .nav > li > a:focus, .bg-success-300 .nav > li > a:hover {
  background-color: #29b866;
  color: #fff !important;
}

.bg-success-300 .navbar-nav > li > a {
  color: #fff;
}

.bg-success-300.small-nav:hover .child-nav {
  background-color: #2ecd71;
}

.bg-success-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #2ecd71;
}

.bg-success-100 {
  background-color: #4bd786;
  border-color: #4bd786;
  color: #fff !important;
}

.bg-success-100 h1, .bg-success-100 h2, .bg-success-100 h3, .bg-success-100 h4, .bg-success-100 h5, .bg-success-100 h6 {
  color: #fff;
}

.bg-success-100 .nav .open > a, .bg-success-100 .nav .open > a:focus, .bg-success-100 .nav .open > a:hover {
  background-color: #36d278;
  color: #fff !important;
}

.bg-success-100 .nav > li > a:focus, .bg-success-100 .nav > li > a:hover {
  background-color: #36d278;
  color: #fff !important;
}

.bg-success-100 .navbar-nav > li > a {
  color: #fff;
}

.bg-success-100.small-nav:hover .child-nav {
  background-color: #4bd786;
}

.bg-success-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #4bd786;
}

.bg-success-500 {
  background-color: #229955;
  border-color: #229955;
  color: #fff !important;
}

.bg-success-500 h1, .bg-success-500 h2, .bg-success-500 h3, .bg-success-500 h4, .bg-success-500 h5, .bg-success-500 h6 {
  color: #fff;
}

.bg-success-500 .nav .open > a, .bg-success-500 .nav .open > a:focus, .bg-success-500 .nav .open > a:hover {
  background-color: #1e8449;
  color: #fff !important;
}

.bg-success-500 .nav > li > a:focus, .bg-success-500 .nav > li > a:hover {
  background-color: #1e8449;
  color: #fff !important;
}

.bg-success-500 .navbar-nav > li > a {
  color: #fff;
}

.bg-success-500.small-nav:hover .child-nav {
  background-color: #229955;
}

.bg-success-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #229955;
}

.bg-success-600 {
  background-color: #1e8449;
  border-color: #1e8449;
  color: #fff !important;
}

.bg-success-600 h1, .bg-success-600 h2, .bg-success-600 h3, .bg-success-600 h4, .bg-success-600 h5, .bg-success-600 h6 {
  color: #fff;
}

.bg-success-600 .nav .open > a, .bg-success-600 .nav .open > a:focus, .bg-success-600 .nav .open > a:hover {
  background-color: #19703e;
  color: #fff !important;
}

.bg-success-600 .nav > li > a:focus, .bg-success-600 .nav > li > a:hover {
  background-color: #19703e;
  color: #fff !important;
}

.bg-success-600 .navbar-nav > li > a {
  color: #fff;
}

.bg-success-600.small-nav:hover .child-nav {
  background-color: #1e8449;
}

.bg-success-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #1e8449;
}

.bg-success-700 {
  background-color: #19703e;
  border-color: #19703e;
  color: #fff !important;
}

.bg-success-700 h1, .bg-success-700 h2, .bg-success-700 h3, .bg-success-700 h4, .bg-success-700 h5, .bg-success-700 h6 {
  color: #fff;
}

.bg-success-700 .nav .open > a, .bg-success-700 .nav .open > a:focus, .bg-success-700 .nav .open > a:hover {
  background-color: #145b32;
  color: #fff !important;
}

.bg-success-700 .nav > li > a:focus, .bg-success-700 .nav > li > a:hover {
  background-color: #145b32;
  color: #fff !important;
}

.bg-success-700 .navbar-nav > li > a {
  color: #fff;
}

.bg-success-700.small-nav:hover .child-nav {
  background-color: #19703e;
}

.bg-success-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #19703e;
}

.bg-warning {
  background-color: #f39c12;
  border-color: #f39c12;
  color: #fff !important;
}

.bg-warning h1, .bg-warning h2, .bg-warning h3, .bg-warning h4, .bg-warning h5, .bg-warning h6 {
  color: #fff;
}

.bg-warning .nav .open > a, .bg-warning .nav .open > a:focus, .bg-warning .nav .open > a:hover {
  background-color: #e08e0b;
  color: #fff !important;
}

.bg-warning .nav > li > a:focus, .bg-warning .nav > li > a:hover {
  background-color: #e08e0b;
  color: #fff !important;
}

.bg-warning .navbar-nav > li > a {
  color: #fff;
}

.bg-warning.small-nav:hover .child-nav {
  background-color: #f39c12;
}

.bg-warning.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #f39c12;
}

.bg-warning-300 {
  background-color: #f5ab36;
  border-color: #f5ab36;
  color: #fff !important;
}

.bg-warning-300 h1, .bg-warning-300 h2, .bg-warning-300 h3, .bg-warning-300 h4, .bg-warning-300 h5, .bg-warning-300 h6 {
  color: #fff;
}

.bg-warning-300 .nav .open > a, .bg-warning-300 .nav .open > a:focus, .bg-warning-300 .nav .open > a:hover {
  background-color: #f4a11e;
  color: #fff !important;
}

.bg-warning-300 .nav > li > a:focus, .bg-warning-300 .nav > li > a:hover {
  background-color: #f4a11e;
  color: #fff !important;
}

.bg-warning-300 .navbar-nav > li > a {
  color: #fff;
}

.bg-warning-300.small-nav:hover .child-nav {
  background-color: #f5ab36;
}

.bg-warning-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #f5ab36;
}

.bg-warning-100 {
  background-color: #f7ba5b;
  border-color: #f7ba5b;
  color: #fff !important;
}

.bg-warning-100 h1, .bg-warning-100 h2, .bg-warning-100 h3, .bg-warning-100 h4, .bg-warning-100 h5, .bg-warning-100 h6 {
  color: #fff;
}

.bg-warning-100 .nav .open > a, .bg-warning-100 .nav .open > a:focus, .bg-warning-100 .nav .open > a:hover {
  background-color: #f5b043;
  color: #fff !important;
}

.bg-warning-100 .nav > li > a:focus, .bg-warning-100 .nav > li > a:hover {
  background-color: #f5b043;
  color: #fff !important;
}

.bg-warning-100 .navbar-nav > li > a {
  color: #fff;
}

.bg-warning-100.small-nav:hover .child-nav {
  background-color: #f7ba5b;
}

.bg-warning-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #f7ba5b;
}

.bg-warning-500 {
  background-color: #e08e0b;
  border-color: #e08e0b;
  color: #fff !important;
}

.bg-warning-500 h1, .bg-warning-500 h2, .bg-warning-500 h3, .bg-warning-500 h4, .bg-warning-500 h5, .bg-warning-500 h6 {
  color: #fff;
}

.bg-warning-500 .nav .open > a, .bg-warning-500 .nav .open > a:focus, .bg-warning-500 .nav .open > a:hover {
  background-color: #c87f0a;
  color: #fff !important;
}

.bg-warning-500 .nav > li > a:focus, .bg-warning-500 .nav > li > a:hover {
  background-color: #c87f0a;
  color: #fff !important;
}

.bg-warning-500 .navbar-nav > li > a {
  color: #fff;
}

.bg-warning-500.small-nav:hover .child-nav {
  background-color: #e08e0b;
}

.bg-warning-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #e08e0b;
}

.bg-warning-600 {
  background-color: #c87f0a;
  border-color: #c87f0a;
  color: #fff !important;
}

.bg-warning-600 h1, .bg-warning-600 h2, .bg-warning-600 h3, .bg-warning-600 h4, .bg-warning-600 h5, .bg-warning-600 h6 {
  color: #fff;
}

.bg-warning-600 .nav .open > a, .bg-warning-600 .nav .open > a:focus, .bg-warning-600 .nav .open > a:hover {
  background-color: #b06f09;
  color: #fff !important;
}

.bg-warning-600 .nav > li > a:focus, .bg-warning-600 .nav > li > a:hover {
  background-color: #b06f09;
  color: #fff !important;
}

.bg-warning-600 .navbar-nav > li > a {
  color: #fff;
}

.bg-warning-600.small-nav:hover .child-nav {
  background-color: #c87f0a;
}

.bg-warning-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #c87f0a;
}

.bg-warning-700 {
  background-color: #b06f09;
  border-color: #b06f09;
  color: #fff !important;
}

.bg-warning-700 h1, .bg-warning-700 h2, .bg-warning-700 h3, .bg-warning-700 h4, .bg-warning-700 h5, .bg-warning-700 h6 {
  color: #fff;
}

.bg-warning-700 .nav .open > a, .bg-warning-700 .nav .open > a:focus, .bg-warning-700 .nav .open > a:hover {
  background-color: #976008;
  color: #fff !important;
}

.bg-warning-700 .nav > li > a:focus, .bg-warning-700 .nav > li > a:hover {
  background-color: #976008;
  color: #fff !important;
}

.bg-warning-700 .navbar-nav > li > a {
  color: #fff;
}

.bg-warning-700.small-nav:hover .child-nav {
  background-color: #b06f09;
}

.bg-warning-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #b06f09;
}

.bg-info {
  background-color: #5bc0de;
  border-color: #5bc0de;
  color: #fff !important;
}

.bg-info h1, .bg-info h2, .bg-info h3, .bg-info h4, .bg-info h5, .bg-info h6 {
  color: #fff;
}

.bg-info .nav .open > a, .bg-info .nav .open > a:focus, .bg-info .nav .open > a:hover {
  background-color: #46b8da;
  color: #fff !important;
}

.bg-info .nav > li > a:focus, .bg-info .nav > li > a:hover {
  background-color: #46b8da;
  color: #fff !important;
}

.bg-info .navbar-nav > li > a {
  color: #fff;
}

.bg-info.small-nav:hover .child-nav {
  background-color: #5bc0de;
}

.bg-info.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #5bc0de;
}

.bg-info-300 {
  background-color: #7bcce4;
  border-color: #7bcce4;
  color: #fff !important;
}

.bg-info-300 h1, .bg-info-300 h2, .bg-info-300 h3, .bg-info-300 h4, .bg-info-300 h5, .bg-info-300 h6 {
  color: #fff;
}

.bg-info-300 .nav .open > a, .bg-info-300 .nav .open > a:focus, .bg-info-300 .nav .open > a:hover {
  background-color: #66c4e0;
  color: #fff !important;
}

.bg-info-300 .nav > li > a:focus, .bg-info-300 .nav > li > a:hover {
  background-color: #66c4e0;
  color: #fff !important;
}

.bg-info-300 .navbar-nav > li > a {
  color: #fff;
}

.bg-info-300.small-nav:hover .child-nav {
  background-color: #7bcce4;
}

.bg-info-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #7bcce4;
}

.bg-info-100 {
  background-color: #9bd8eb;
  border-color: #9bd8eb;
  color: #fff !important;
}

.bg-info-100 h1, .bg-info-100 h2, .bg-info-100 h3, .bg-info-100 h4, .bg-info-100 h5, .bg-info-100 h6 {
  color: #fff;
}

.bg-info-100 .nav .open > a, .bg-info-100 .nav .open > a:focus, .bg-info-100 .nav .open > a:hover {
  background-color: #85d0e7;
  color: #fff !important;
}

.bg-info-100 .nav > li > a:focus, .bg-info-100 .nav > li > a:hover {
  background-color: #85d0e7;
  color: #fff !important;
}

.bg-info-100 .navbar-nav > li > a {
  color: #fff;
}

.bg-info-100.small-nav:hover .child-nav {
  background-color: #9bd8eb;
}

.bg-info-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #9bd8eb;
}

.bg-info-500 {
  background-color: #46b8da;
  border-color: #46b8da;
  color: #fff !important;
}

.bg-info-500 h1, .bg-info-500 h2, .bg-info-500 h3, .bg-info-500 h4, .bg-info-500 h5, .bg-info-500 h6 {
  color: #fff;
}

.bg-info-500 .nav .open > a, .bg-info-500 .nav .open > a:focus, .bg-info-500 .nav .open > a:hover {
  background-color: #31b0d5;
  color: #fff !important;
}

.bg-info-500 .nav > li > a:focus, .bg-info-500 .nav > li > a:hover {
  background-color: #31b0d5;
  color: #fff !important;
}

.bg-info-500 .navbar-nav > li > a {
  color: #fff;
}

.bg-info-500.small-nav:hover .child-nav {
  background-color: #46b8da;
}

.bg-info-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #46b8da;
}

.bg-info-600 {
  background-color: #31b0d5;
  border-color: #31b0d5;
  color: #fff !important;
}

.bg-info-600 h1, .bg-info-600 h2, .bg-info-600 h3, .bg-info-600 h4, .bg-info-600 h5, .bg-info-600 h6 {
  color: #fff;
}

.bg-info-600 .nav .open > a, .bg-info-600 .nav .open > a:focus, .bg-info-600 .nav .open > a:hover {
  background-color: #28a1c5;
  color: #fff !important;
}

.bg-info-600 .nav > li > a:focus, .bg-info-600 .nav > li > a:hover {
  background-color: #28a1c5;
  color: #fff !important;
}

.bg-info-600 .navbar-nav > li > a {
  color: #fff;
}

.bg-info-600.small-nav:hover .child-nav {
  background-color: #31b0d5;
}

.bg-info-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #31b0d5;
}

.bg-info-700 {
  background-color: #28a1c5;
  border-color: #28a1c5;
  color: #fff !important;
}

.bg-info-700 h1, .bg-info-700 h2, .bg-info-700 h3, .bg-info-700 h4, .bg-info-700 h5, .bg-info-700 h6 {
  color: #fff;
}

.bg-info-700 .nav .open > a, .bg-info-700 .nav .open > a:focus, .bg-info-700 .nav .open > a:hover {
  background-color: #2390b0;
  color: #fff !important;
}

.bg-info-700 .nav > li > a:focus, .bg-info-700 .nav > li > a:hover {
  background-color: #2390b0;
  color: #fff !important;
}

.bg-info-700 .navbar-nav > li > a {
  color: #fff;
}

.bg-info-700.small-nav:hover .child-nav {
  background-color: #28a1c5;
}

.bg-info-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #28a1c5;
}

.bg-black {
  background-color: #292929;
  border-color: #292929;
  color: #fff !important;
}

.bg-black h1, .bg-black h2, .bg-black h3, .bg-black h4, .bg-black h5, .bg-black h6 {
  color: #fff;
}

.bg-black .nav .open > a, .bg-black .nav .open > a:focus, .bg-black .nav .open > a:hover {
  background-color: #1c1c1c;
  color: #fff !important;
}

.bg-black .nav > li > a:focus, .bg-black .nav > li > a:hover {
  background-color: #1c1c1c;
  color: #fff !important;
}

.bg-black .navbar-nav > li > a {
  color: #fff;
}

.bg-black.small-nav:hover .child-nav {
  background-color: #292929;
}

.bg-black.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #292929;
}

.bg-black-300 {
  background-color: #3c3c3c;
  border-color: #3c3c3c;
  color: #fff !important;
}

.bg-black-300 h1, .bg-black-300 h2, .bg-black-300 h3, .bg-black-300 h4, .bg-black-300 h5, .bg-black-300 h6 {
  color: #fff;
}

.bg-black-300 .nav .open > a, .bg-black-300 .nav .open > a:focus, .bg-black-300 .nav .open > a:hover {
  background-color: #2f2f2f;
  color: #fff !important;
}

.bg-black-300 .nav > li > a:focus, .bg-black-300 .nav > li > a:hover {
  background-color: #2f2f2f;
  color: #fff !important;
}

.bg-black-300 .navbar-nav > li > a {
  color: #fff;
}

.bg-black-300.small-nav:hover .child-nav {
  background-color: #3c3c3c;
}

.bg-black-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #3c3c3c;
}

.bg-black-100 {
  background-color: #4f4f4f;
  border-color: #4f4f4f;
  color: #fff !important;
}

.bg-black-100 h1, .bg-black-100 h2, .bg-black-100 h3, .bg-black-100 h4, .bg-black-100 h5, .bg-black-100 h6 {
  color: #fff;
}

.bg-black-100 .nav .open > a, .bg-black-100 .nav .open > a:focus, .bg-black-100 .nav .open > a:hover {
  background-color: #434343;
  color: #fff !important;
}

.bg-black-100 .nav > li > a:focus, .bg-black-100 .nav > li > a:hover {
  background-color: #434343;
  color: #fff !important;
}

.bg-black-100 .navbar-nav > li > a {
  color: #fff;
}

.bg-black-100.small-nav:hover .child-nav {
  background-color: #4f4f4f;
}

.bg-black-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #4f4f4f;
}

.bg-black-500 {
  background-color: #1c1c1c;
  border-color: #1c1c1c;
  color: #fff !important;
}

.bg-black-500 h1, .bg-black-500 h2, .bg-black-500 h3, .bg-black-500 h4, .bg-black-500 h5, .bg-black-500 h6 {
  color: #fff;
}

.bg-black-500 .nav .open > a, .bg-black-500 .nav .open > a:focus, .bg-black-500 .nav .open > a:hover {
  background-color: #101010;
  color: #fff !important;
}

.bg-black-500 .nav > li > a:focus, .bg-black-500 .nav > li > a:hover {
  background-color: #101010;
  color: #fff !important;
}

.bg-black-500 .navbar-nav > li > a {
  color: #fff;
}

.bg-black-500.small-nav:hover .child-nav {
  background-color: #1c1c1c;
}

.bg-black-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #1c1c1c;
}

.bg-black-600 {
  background-color: #101010;
  border-color: #101010;
  color: #fff !important;
}

.bg-black-600 h1, .bg-black-600 h2, .bg-black-600 h3, .bg-black-600 h4, .bg-black-600 h5, .bg-black-600 h6 {
  color: #fff;
}

.bg-black-600 .nav .open > a, .bg-black-600 .nav .open > a:focus, .bg-black-600 .nav .open > a:hover {
  background-color: #030303;
  color: #fff !important;
}

.bg-black-600 .nav > li > a:focus, .bg-black-600 .nav > li > a:hover {
  background-color: #030303;
  color: #fff !important;
}

.bg-black-600 .navbar-nav > li > a {
  color: #fff;
}

.bg-black-600.small-nav:hover .child-nav {
  background-color: #101010;
}

.bg-black-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #101010;
}

.bg-black-700 {
  background-color: #030303;
  border-color: #030303;
  color: #fff !important;
}

.bg-black-700 h1, .bg-black-700 h2, .bg-black-700 h3, .bg-black-700 h4, .bg-black-700 h5, .bg-black-700 h6 {
  color: #fff;
}

.bg-black-700 .nav .open > a, .bg-black-700 .nav .open > a:focus, .bg-black-700 .nav .open > a:hover {
  background-color: black;
  color: #fff !important;
}

.bg-black-700 .nav > li > a:focus, .bg-black-700 .nav > li > a:hover {
  background-color: black;
  color: #fff !important;
}

.bg-black-700 .navbar-nav > li > a {
  color: #fff;
}

.bg-black-700.small-nav:hover .child-nav {
  background-color: #030303;
}

.bg-black-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #030303;
}

.bg-light-black {
  background-color: #494949;
  border-color: #494949;
  color: #fff !important;
}

.bg-light-black h1, .bg-light-black h2, .bg-light-black h3, .bg-light-black h4, .bg-light-black h5, .bg-light-black h6 {
  color: #fff;
}

.bg-light-black .nav .open > a, .bg-light-black .nav .open > a:focus, .bg-light-black .nav .open > a:hover {
  background-color: #3c3c3c;
  color: #fff !important;
}

.bg-light-black .nav > li > a:focus, .bg-light-black .nav > li > a:hover {
  background-color: #3c3c3c;
  color: #fff !important;
}

.bg-light-black .navbar-nav > li > a {
  color: #fff;
}

.bg-light-black.small-nav:hover .child-nav {
  background-color: #494949;
}

.bg-light-black.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #494949;
}

.bg-light-black-300 {
  background-color: #5c5c5c;
  border-color: #5c5c5c;
  color: #fff !important;
}

.bg-light-black-300 h1, .bg-light-black-300 h2, .bg-light-black-300 h3, .bg-light-black-300 h4, .bg-light-black-300 h5, .bg-light-black-300 h6 {
  color: #fff;
}

.bg-light-black-300 .nav .open > a, .bg-light-black-300 .nav .open > a:focus, .bg-light-black-300 .nav .open > a:hover {
  background-color: #4f4f4f;
  color: #fff !important;
}

.bg-light-black-300 .nav > li > a:focus, .bg-light-black-300 .nav > li > a:hover {
  background-color: #4f4f4f;
  color: #fff !important;
}

.bg-light-black-300 .navbar-nav > li > a {
  color: #fff;
}

.bg-light-black-300.small-nav:hover .child-nav {
  background-color: #5c5c5c;
}

.bg-light-black-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #5c5c5c;
}

.bg-light-black-100 {
  background-color: #6f6f6f;
  border-color: #6f6f6f;
  color: #fff !important;
}

.bg-light-black-100 h1, .bg-light-black-100 h2, .bg-light-black-100 h3, .bg-light-black-100 h4, .bg-light-black-100 h5, .bg-light-black-100 h6 {
  color: #fff;
}

.bg-light-black-100 .nav .open > a, .bg-light-black-100 .nav .open > a:focus, .bg-light-black-100 .nav .open > a:hover {
  background-color: #636363;
  color: #fff !important;
}

.bg-light-black-100 .nav > li > a:focus, .bg-light-black-100 .nav > li > a:hover {
  background-color: #636363;
  color: #fff !important;
}

.bg-light-black-100 .navbar-nav > li > a {
  color: #fff;
}

.bg-light-black-100.small-nav:hover .child-nav {
  background-color: #6f6f6f;
}

.bg-light-black-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #6f6f6f;
}

.bg-light-black-500 {
  background-color: #3c3c3c;
  border-color: #3c3c3c;
  color: #fff !important;
}

.bg-light-black-500 h1, .bg-light-black-500 h2, .bg-light-black-500 h3, .bg-light-black-500 h4, .bg-light-black-500 h5, .bg-light-black-500 h6 {
  color: #fff;
}

.bg-light-black-500 .nav .open > a, .bg-light-black-500 .nav .open > a:focus, .bg-light-black-500 .nav .open > a:hover {
  background-color: #303030;
  color: #fff !important;
}

.bg-light-black-500 .nav > li > a:focus, .bg-light-black-500 .nav > li > a:hover {
  background-color: #303030;
  color: #fff !important;
}

.bg-light-black-500 .navbar-nav > li > a {
  color: #fff;
}

.bg-light-black-500.small-nav:hover .child-nav {
  background-color: #3c3c3c;
}

.bg-light-black-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #3c3c3c;
}

.bg-light-black-600 {
  background-color: #303030;
  border-color: #303030;
  color: #fff !important;
}

.bg-light-black-600 h1, .bg-light-black-600 h2, .bg-light-black-600 h3, .bg-light-black-600 h4, .bg-light-black-600 h5, .bg-light-black-600 h6 {
  color: #fff;
}

.bg-light-black-600 .nav .open > a, .bg-light-black-600 .nav .open > a:focus, .bg-light-black-600 .nav .open > a:hover {
  background-color: #232323;
  color: #fff !important;
}

.bg-light-black-600 .nav > li > a:focus, .bg-light-black-600 .nav > li > a:hover {
  background-color: #232323;
  color: #fff !important;
}

.bg-light-black-600 .navbar-nav > li > a {
  color: #fff;
}

.bg-light-black-600.small-nav:hover .child-nav {
  background-color: #303030;
}

.bg-light-black-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #303030;
}

.bg-light-black-700 {
  background-color: #232323;
  border-color: #232323;
  color: #fff !important;
}

.bg-light-black-700 h1, .bg-light-black-700 h2, .bg-light-black-700 h3, .bg-light-black-700 h4, .bg-light-black-700 h5, .bg-light-black-700 h6 {
  color: #fff;
}

.bg-light-black-700 .nav .open > a, .bg-light-black-700 .nav .open > a:focus, .bg-light-black-700 .nav .open > a:hover {
  background-color: #161616;
  color: #fff !important;
}

.bg-light-black-700 .nav > li > a:focus, .bg-light-black-700 .nav > li > a:hover {
  background-color: #161616;
  color: #fff !important;
}

.bg-light-black-700 .navbar-nav > li > a {
  color: #fff;
}

.bg-light-black-700.small-nav:hover .child-nav {
  background-color: #232323;
}

.bg-light-black-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #232323;
}

.bg-gray {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  color: #494949 !important;
}

.bg-gray h1, .bg-gray h2, .bg-gray h3, .bg-gray h4, .bg-gray h5, .bg-gray h6 {
  color: #494949;
}

.bg-gray .nav .open > a, .bg-gray .nav .open > a:focus, .bg-gray .nav .open > a:hover {
  background-color: #e5e5e5;
  color: #494949 !important;
}

.bg-gray .nav > li > a:focus, .bg-gray .nav > li > a:hover {
  background-color: #e5e5e5;
  color: #494949 !important;
}

.bg-gray .navbar-nav > li > a {
  color: #494949;
}

.bg-gray.small-nav:hover .child-nav {
  background-color: #f2f2f2;
}

.bg-gray.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #f2f2f2;
}

.bg-gray-300 {
  background-color: white;
  border-color: white;
  color: #494949 !important;
}

.bg-gray-300 h1, .bg-gray-300 h2, .bg-gray-300 h3, .bg-gray-300 h4, .bg-gray-300 h5, .bg-gray-300 h6 {
  color: #494949;
}

.bg-gray-300 .nav .open > a, .bg-gray-300 .nav .open > a:focus, .bg-gray-300 .nav .open > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-gray-300 .nav > li > a:focus, .bg-gray-300 .nav > li > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-gray-300 .navbar-nav > li > a {
  color: #494949;
}

.bg-gray-300.small-nav:hover .child-nav {
  background-color: white;
}

.bg-gray-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: white;
}

.bg-gray-100 {
  background-color: white;
  border-color: white;
  color: #494949 !important;
}

.bg-gray-100 h1, .bg-gray-100 h2, .bg-gray-100 h3, .bg-gray-100 h4, .bg-gray-100 h5, .bg-gray-100 h6 {
  color: #494949;
}

.bg-gray-100 .nav .open > a, .bg-gray-100 .nav .open > a:focus, .bg-gray-100 .nav .open > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-gray-100 .nav > li > a:focus, .bg-gray-100 .nav > li > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-gray-100 .navbar-nav > li > a {
  color: #494949;
}

.bg-gray-100.small-nav:hover .child-nav {
  background-color: white;
}

.bg-gray-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: white;
}

.bg-gray-500 {
  background-color: #e5e5e5;
  border-color: #e5e5e5;
  color: #494949 !important;
}

.bg-gray-500 h1, .bg-gray-500 h2, .bg-gray-500 h3, .bg-gray-500 h4, .bg-gray-500 h5, .bg-gray-500 h6 {
  color: #494949;
}

.bg-gray-500 .nav .open > a, .bg-gray-500 .nav .open > a:focus, .bg-gray-500 .nav .open > a:hover {
  background-color: #d9d9d9;
  color: #494949 !important;
}

.bg-gray-500 .nav > li > a:focus, .bg-gray-500 .nav > li > a:hover {
  background-color: #d9d9d9;
  color: #494949 !important;
}

.bg-gray-500 .navbar-nav > li > a {
  color: #494949;
}

.bg-gray-500.small-nav:hover .child-nav {
  background-color: #e5e5e5;
}

.bg-gray-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #e5e5e5;
}

.bg-gray-600 {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
  color: #494949 !important;
}

.bg-gray-600 h1, .bg-gray-600 h2, .bg-gray-600 h3, .bg-gray-600 h4, .bg-gray-600 h5, .bg-gray-600 h6 {
  color: #494949;
}

.bg-gray-600 .nav .open > a, .bg-gray-600 .nav .open > a:focus, .bg-gray-600 .nav .open > a:hover {
  background-color: #cccccc;
  color: #494949 !important;
}

.bg-gray-600 .nav > li > a:focus, .bg-gray-600 .nav > li > a:hover {
  background-color: #cccccc;
  color: #494949 !important;
}

.bg-gray-600 .navbar-nav > li > a {
  color: #494949;
}

.bg-gray-600.small-nav:hover .child-nav {
  background-color: #d9d9d9;
}

.bg-gray-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #d9d9d9;
}

.bg-gray-700 {
  background-color: #cccccc;
  border-color: #cccccc;
  color: #494949 !important;
}

.bg-gray-700 h1, .bg-gray-700 h2, .bg-gray-700 h3, .bg-gray-700 h4, .bg-gray-700 h5, .bg-gray-700 h6 {
  color: #494949;
}

.bg-gray-700 .nav .open > a, .bg-gray-700 .nav .open > a:focus, .bg-gray-700 .nav .open > a:hover {
  background-color: #bfbfbf;
  color: #494949 !important;
}

.bg-gray-700 .nav > li > a:focus, .bg-gray-700 .nav > li > a:hover {
  background-color: #bfbfbf;
  color: #494949 !important;
}

.bg-gray-700 .navbar-nav > li > a {
  color: #494949;
}

.bg-gray-700.small-nav:hover .child-nav {
  background-color: #cccccc;
}

.bg-gray-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #cccccc;
}

.bg-white {
  background-color: #fff;
  border-color: #fff;
  color: #494949 !important;
}

.bg-white h1, .bg-white h2, .bg-white h3, .bg-white h4, .bg-white h5, .bg-white h6 {
  color: #494949;
}

.bg-white .nav .open > a, .bg-white .nav .open > a:focus, .bg-white .nav .open > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-white .nav > li > a:focus, .bg-white .nav > li > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-white .navbar-nav > li > a {
  color: #494949;
}

.bg-white.small-nav:hover .child-nav {
  background-color: #fff;
}

.bg-white.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #fff;
}

.bg-white-300 {
  background-color: white;
  border-color: white;
  color: #494949 !important;
}

.bg-white-300 h1, .bg-white-300 h2, .bg-white-300 h3, .bg-white-300 h4, .bg-white-300 h5, .bg-white-300 h6 {
  color: #494949;
}

.bg-white-300 .nav .open > a, .bg-white-300 .nav .open > a:focus, .bg-white-300 .nav .open > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-white-300 .nav > li > a:focus, .bg-white-300 .nav > li > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-white-300 .navbar-nav > li > a {
  color: #494949;
}

.bg-white-300.small-nav:hover .child-nav {
  background-color: white;
}

.bg-white-300.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: white;
}

.bg-white-100 {
  background-color: white;
  border-color: white;
  color: #494949 !important;
}

.bg-white-100 h1, .bg-white-100 h2, .bg-white-100 h3, .bg-white-100 h4, .bg-white-100 h5, .bg-white-100 h6 {
  color: #494949;
}

.bg-white-100 .nav .open > a, .bg-white-100 .nav .open > a:focus, .bg-white-100 .nav .open > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-white-100 .nav > li > a:focus, .bg-white-100 .nav > li > a:hover {
  background-color: #f2f2f2;
  color: #494949 !important;
}

.bg-white-100 .navbar-nav > li > a {
  color: #494949;
}

.bg-white-100.small-nav:hover .child-nav {
  background-color: white;
}

.bg-white-100.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: white;
}

.bg-white-500 {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
  color: #494949 !important;
}

.bg-white-500 h1, .bg-white-500 h2, .bg-white-500 h3, .bg-white-500 h4, .bg-white-500 h5, .bg-white-500 h6 {
  color: #494949;
}

.bg-white-500 .nav .open > a, .bg-white-500 .nav .open > a:focus, .bg-white-500 .nav .open > a:hover {
  background-color: #e6e6e6;
  color: #494949 !important;
}

.bg-white-500 .nav > li > a:focus, .bg-white-500 .nav > li > a:hover {
  background-color: #e6e6e6;
  color: #494949 !important;
}

.bg-white-500 .navbar-nav > li > a {
  color: #494949;
}

.bg-white-500.small-nav:hover .child-nav {
  background-color: #f2f2f2;
}

.bg-white-500.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #f2f2f2;
}

.bg-white-600 {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #494949 !important;
}

.bg-white-600 h1, .bg-white-600 h2, .bg-white-600 h3, .bg-white-600 h4, .bg-white-600 h5, .bg-white-600 h6 {
  color: #494949;
}

.bg-white-600 .nav .open > a, .bg-white-600 .nav .open > a:focus, .bg-white-600 .nav .open > a:hover {
  background-color: #d9d9d9;
  color: #494949 !important;
}

.bg-white-600 .nav > li > a:focus, .bg-white-600 .nav > li > a:hover {
  background-color: #d9d9d9;
  color: #494949 !important;
}

.bg-white-600 .navbar-nav > li > a {
  color: #494949;
}

.bg-white-600.small-nav:hover .child-nav {
  background-color: #e6e6e6;
}

.bg-white-600.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #e6e6e6;
}

.bg-white-700 {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
  color: #494949 !important;
}

.bg-white-700 h1, .bg-white-700 h2, .bg-white-700 h3, .bg-white-700 h4, .bg-white-700 h5, .bg-white-700 h6 {
  color: #494949;
}

.bg-white-700 .nav .open > a, .bg-white-700 .nav .open > a:focus, .bg-white-700 .nav .open > a:hover {
  background-color: #cccccc;
  color: #494949 !important;
}

.bg-white-700 .nav > li > a:focus, .bg-white-700 .nav > li > a:hover {
  background-color: #cccccc;
  color: #494949 !important;
}

.bg-white-700 .navbar-nav > li > a {
  color: #494949;
}

.bg-white-700.small-nav:hover .child-nav {
  background-color: #d9d9d9;
}

.bg-white-700.small-nav:hover .side-nav > li:not(.has-children) > a > span {
  background-color: #d9d9d9;
}

/*--------------------------------------------------------------
# COLOR CLASSES
--------------------------------------------------------------*/
.color-primary {
  color: #3498db !important;
}

.color-danger {
  color: #e74c3c !important;
}

.color-success {
  color: #27ae60 !important;
}

.color-warning {
  color: #f39c12 !important;
}

.color-info {
  color: #5bc0de !important;
}

.color-black {
  color: #292929 !important;
}

.color-light-black {
  color: #494949 !important;
}

.color-gray {
  color: #f2f2f2 !important;
}

.color-white {
  color: #fff !important;
}

/*--------------------------------------------------------------
# BORDER COLORS
--------------------------------------------------------------*/
.border-primary {
  border-color: #3498db;
}

.border-primary-300 {
  border-color: #54a8e1;
}

.border-primary-100 {
  border-color: #75b9e7;
}

.border-primary-500 {
  border-color: #258cd1;
}

.border-primary-600 {
  border-color: #217dbb;
}

.border-primary-700 {
  border-color: #1d6fa5;
}

.border-danger {
  border-color: #e74c3c;
}

.border-danger-300 {
  border-color: #eb6b5e;
}

.border-danger-100 {
  border-color: #ef8b80;
}

.border-danger-500 {
  border-color: #e43725;
}

.border-danger-600 {
  border-color: #d62c1a;
}

.border-danger-700 {
  border-color: #bf2718;
}

.border-success {
  border-color: #27ae60;
}

.border-success-300 {
  border-color: #2ecd71;
}

.border-success-100 {
  border-color: #4bd786;
}

.border-success-500 {
  border-color: #229955;
}

.border-success-600 {
  border-color: #1e8449;
}

.border-success-700 {
  border-color: #19703e;
}

.border-warning {
  border-color: #f39c12;
}

.border-warning-300 {
  border-color: #f5ab36;
}

.border-warning-100 {
  border-color: #f7ba5b;
}

.border-warning-500 {
  border-color: #e08e0b;
}

.border-warning-600 {
  border-color: #c87f0a;
}

.border-warning-700 {
  border-color: #b06f09;
}

.border-info {
  border-color: #5bc0de;
}

.border-info-300 {
  border-color: #7bcce4;
}

.border-info-100 {
  border-color: #9bd8eb;
}

.border-info-500 {
  border-color: #46b8da;
}

.border-info-600 {
  border-color: #31b0d5;
}

.border-info-700 {
  border-color: #28a1c5;
}

.border-black {
  border-color: #292929;
}

.border-black-300 {
  border-color: #3c3c3c;
}

.border-black-100 {
  border-color: #4f4f4f;
}

.border-black-500 {
  border-color: #1c1c1c;
}

.border-black-600 {
  border-color: #101010;
}

.border-black-700 {
  border-color: #030303;
}

.border-gray {
  border-color: #f2f2f2;
}

.border-gray-300 {
  border-color: white;
}

.border-gray-100 {
  border-color: white;
}

.border-gray-500 {
  border-color: #e5e5e5;
}

.border-gray-600 {
  border-color: #d9d9d9;
}

.border-gray-700 {
  border-color: #cccccc;
}

.mt-n {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-n {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.ml-n {
  margin-left: 0 !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.mr-n {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.m-n {
  margin: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.pt-n {
  padding-top: 0 !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pb-n {
  padding-bottom: 0 !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-n {
  padding-left: 0 !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-n {
  padding-right: 0 !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.p-n {
  padding: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-35 {
  padding: 35px !important;
}

.p-40 {
  padding: 40px !important;
}

.p-45 {
  padding: 45px !important;
}

.p-50 {
  padding: 50px !important;
}

/*--------------------------------------------------------------
# BORDERS
--------------------------------------------------------------*/
.no-border {
  border-width: 0px !important;
}

.no-border-top {
  border-top-width: 0px !important;
}

.no-border-bottom {
  border-bottom-width: 0px !important;
}

.no-border-left {
  border-left-width: 0px !important;
}

.no-border-right {
  border-right-width: 0px !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-1-top {
  border-top-width: 1px !important;
}

.border-1-bottom {
  border-bottom-width: 1px !important;
}

.border-1-left {
  border-left-width: 1px !important;
}

.border-1-right {
  border-right-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-2-top {
  border-top-width: 2px !important;
}

.border-2-bottom {
  border-bottom-width: 2px !important;
}

.border-2-left {
  border-left-width: 2px !important;
}

.border-2-right {
  border-right-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-3-top {
  border-top-width: 3px !important;
}

.border-3-bottom {
  border-bottom-width: 3px !important;
}

.border-3-left {
  border-left-width: 3px !important;
}

.border-3-right {
  border-right-width: 3px !important;
}

/*--------------------------------------------------------------
# BORDER RADIUS
--------------------------------------------------------------*/
.no-radius {
  border-radius: 0px !important;
}

.no-radius-top-left {
  border-top-left-radius: 0px !important;
}

.no-radius-top-right {
  border-top-right-radius: 0px !important;
}

.no-radius-bottom-right {
  border-bottom-right-radius: 0px !important;
}

.no-radius-bottom-left {
  border-bottom-left-radius: 0px !important;
}

.border-rad-2 {
  border-radius: 2px !important;
}

.border-rad-2-top-left {
  border-top-left-radius: 2px !important;
}

.border-rad-2-top-right {
  border-top-right-radius: 2px !important;
}

.border-rad-2-bottom-right {
  border-bottom-right-radius: 2px !important;
}

.border-rad-2-bottom-left {
  border-bottom-left-radius: 2px !important;
}

.border-rad-4 {
  border-radius: 4px !important;
}

.border-rad-4-top-left {
  border-top-left-radius: 4px !important;
}

.border-rad-4-top-right {
  border-top-right-radius: 4px !important;
}

.border-rad-4-bottom-right {
  border-bottom-right-radius: 4px !important;
}

.border-rad-4-bottom-left {
  border-bottom-left-radius: 4px !important;
}

.border-rad-6 {
  border-radius: 6px !important;
}

.border-rad-6-top-left {
  border-top-left-radius: 6px !important;
}

.border-rad-6-top-right {
  border-top-right-radius: 6px !important;
}

.border-rad-6-bottom-right {
  border-bottom-right-radius: 6px !important;
}

.border-rad-6-bottom-left {
  border-bottom-left-radius: 6px !important;
}

.border-radius-50 {
  border-radius: 50%;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.img-size-10 {
  height: 10px !important;
  width: auto !important;
}

.img-size-20 {
  height: 20px !important;
  width: auto !important;
}

.img-size-30 {
  height: 30px !important;
  width: auto !important;
}

.img-size-35 {
  height: 35px !important;
  width: auto !important;
}

.img-size-40 {
  height: 40px !important;
  width: auto !important;
}

.img-size-50 {
  height: 50px !important;
  width: auto !important;
}

.img-size-60 {
  height: 60px !important;
  width: auto !important;
}

.img-size-70 {
  height: 70px !important;
  width: auto !important;
}

.img-size-80 {
  height: 80px !important;
  width: auto !important;
}

.img-size-90 {
  height: 90px !important;
  width: auto !important;
}

.img-size-100 {
  height: 100px !important;
  width: auto !important;
}

.img-size-150 {
  height: 150px !important;
  width: auto !important;
}

.img-size-200 {
  height: 200px !important;
  width: auto !important;
}

.img-size-250 {
  height: 250px !important;
  width: auto !important;
}

.img-size-300 {
  height: 300px !important;
  width: auto !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-size-36 {
  font-size: 36px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.font-size-44 {
  font-size: 44px !important;
}

.font-size-48 {
  font-size: 48px !important;
}

.line-height-10 {
  line-height: 10px !important;
}

.line-height-20 {
  line-height: 20px !important;
}

.line-height-30 {
  line-height: 30px !important;
}

.line-height-35 {
  line-height: 35px !important;
}

.line-height-40 {
  line-height: 40px !important;
}

.line-height-50 {
  line-height: 50px !important;
}

.line-height-60 {
  line-height: 60px !important;
}

.line-height-70 {
  line-height: 70px !important;
}

.line-height-80 {
  line-height: 80px !important;
}

.line-height-90 {
  line-height: 90px !important;
}

.line-height-100 {
  line-height: 100px !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

/*--------------------------------------------------------------
# TOP NAVBAR
--------------------------------------------------------------*/
.navbar {
  border-radius: 0;
  border: 0px;
  margin-bottom: 0;
}

.navbar.box-shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.navbar a:hover {
  text-decoration: none;
  color: #292929 !important;
}

.navbar-toggle {
  font-size: 20px;
}

.navbar-header {
  min-width: 270px;
  padding-left: 15px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

.navbar-header .navbar-brand {
  padding: 12px;
}

.navbar-header .logo {
  height: 26px;
}

.navbar-header.small-nav-header {
  min-width: 50px;
  width: 50px;
}

.navbar-header.small-nav-header .navbar-brand {
  display: none;
}

.small-nav-handle {
  display: inline-block;
  width: 50px;
  height: 50px;
  float: right;
  cursor: pointer;
  text-align: center;
}

.small-nav-handle .fa {
  line-height: 50px;
}

.dropdown-menu {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  min-width: 220px;
  padding: 0;
  border: 0;
}

.dropdown-menu > li:first-child {
  padding-top: 8px;
}

.dropdown-menu > li > a {
  padding: 8px 20px;
}

.dropdown-menu > li > a .fa {
  margin-right: 5px;
}

.dropdown-menu .divider {
  margin: 5px 0;
}

.dropdown-menu .profile-menu {
  padding: 15px !important;
}

.dropdown-menu .profile-menu .profile-img {
  width: 50px;
}

.dropdown-menu .profile-menu .profile-name {
  width: 120px;
  float: right;
}

.dropdown-menu .profile-menu .profile-name h6 {
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 1.75;
}

.dropdown-menu .profile-menu .profile-name a {
  font-size: 85%;
}

.dropdown-menu.animated {
  animation-duration: 0.4s;
}

@media (min-width: 992px) {
  .top-navbar-fixed .top-navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .top-navbar-fixed .content-wrapper {
    margin-top: 50px;
  }
}

/*--------------------------------------------------------------
# SIDEBARS
--------------------------------------------------------------*/
.content-wrapper {
  min-height: 100vh;
  position: relative;
}

@media (min-width: 769px) {
  .content-wrapper {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .content-container {
    display: table-row;
  }
  .left-sidebar, .main-page, .right-sidebar {
    display: table-cell;
    vertical-align: top;
  }
  .right-sidebar {
    width: 400px;
    height: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    position: absolute;
    right: -400px;
    z-index: 9;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .left-sidebar {
    width: 270px;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
}

.right-sidebar.fixed-sidebar .sidebar-content {
  position: fixed;
  width: 400px;
  height: calc(100% - 50px);
  overflow: scroll;
  right: -400px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.right-sidebar .close-icon {
  float: right;
  cursor: pointer;
}

.left-sidebar {
  position: relative;
}

.left-sidebar .user-info {
  text-align: center;
  padding-top: 15px;
  overflow: hidden;
  height: 170px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.left-sidebar .user-info .title {
  margin: 0;
  margin-top: 5px;
  line-height: 1.75;
  color: inherit !important;
}

.left-sidebar .user-info.closed {
  height: 0px;
}

.left-sidebar .purchase-btn {
  margin-bottom: 30px;
  text-align: center;
}

.left-sidebar .side-nav {
  list-style: none;
  padding-left: 0;
}

.left-sidebar .side-nav .nav-header {
  font-size: 80%;
  text-transform: uppercase;
  padding-left: 15px;
  padding-top: 10px;
  opacity: 0.8;
}

.left-sidebar .side-nav .nav-header:first-child {
  padding-top: 0;
}

.left-sidebar .side-nav a {
  color: inherit;
}

.left-sidebar .side-nav li {
  position: relative;
}

.left-sidebar .side-nav li .fa {
  width: 25px;
  display: inline-block;
}

.left-sidebar .side-nav li.has-children .arrow {
  float: right;
  padding: 5px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.left-sidebar .side-nav li a {
  padding: 10px 0;
  padding-left: 15px;
  display: block;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.left-sidebar .side-nav li a:hover {
  background: rgba(0, 0, 0, 0.15);
  color: inherit;
}

.left-sidebar .side-nav li.active {
  background: rgba(0, 0, 0, 0.15);
}

.left-sidebar .side-nav .child-nav {
  list-style: none;
  padding-left: 0;
}

.left-sidebar .side-nav .child-nav a {
  padding-left: 40px;
}

.left-sidebar .side-nav .has-children.open {
  background: rgba(0, 0, 0, 0.15);
}

.left-sidebar .side-nav .has-children.open .arrow {
  transform: rotate(90deg);
  padding-top: 15px;
}

.left-sidebar.small-nav {
  width: 50px;
  z-index: 8;
}

.left-sidebar.small-nav .user-info {
  display: none;
}

.left-sidebar.small-nav li span {
  display: none;
}

.left-sidebar.small-nav .arrow {
  display: none !important;
}

.left-sidebar.small-nav .sidebar-content {
  width: 50px;
}

.left-sidebar.small-nav .child-nav {
  display: none !important;
  position: absolute;
  top: 0;
  left: 50px;
  width: 270px;
  z-index: 9;
}

.left-sidebar.small-nav .side-nav > li {
  position: relative;
}

.left-sidebar.small-nav .side-nav > li:hover > a > span {
  display: block;
  position: absolute;
  top: 0;
  left: 50px;
  width: 270px;
  padding: 10px 0;
  padding-left: 15px;
}

.left-sidebar.small-nav .purchase-btn {
  display: none;
}

.left-sidebar.small-nav .has-children {
  position: relative;
}

.left-sidebar.small-nav .has-children:hover .child-nav {
  display: block !important;
}

.left-sidebar.small-nav .has-children:hover .child-nav a {
  padding-left: 20px;
}

.left-sidebar.small-nav .has-children:hover .child-nav span {
  display: inline-block;
}

.left-sidebar.small-nav:hover .sidebar-content {
  width: 270px;
}

@media (max-width: 991px) {
  .left-sidebar {
    display: none;
  }
}

@media (max-width: 1280px) {
  .next-btn {
    margin: 5px 5px 5px 0;
  }
}

@media (min-width: 991px) {
  .left-sidebar.fixed-sidebar .sidebar-content {
    position: fixed;
    width: 270px;
    height: calc(100% - 50px);
    overflow: scroll;
    overflow-x: hidden;
  }
  .left-sidebar.small-nav .sidebar-content {
    width: 50px;
    position: relative;
    overflow: hidden;
  }
  .left-sidebar.small-nav .sidebar-content::-webkit-scrollbar {
    width: 0px;
  }
}

/*browser scrollbar*/
.sidebar-content::-webkit-scrollbar {
  width: 8px;
}

.sidebar-content::-webkit-scrollbar-track {
  box-shadow: none;
}

.sidebar-content::-webkit-scrollbar-thumb {
  background: rgba(30, 30, 30, 0.8);
  box-shadow: none;
  border-radius: 5px;
}

.sidebar-content::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(210, 210, 210, 0.4);
}

/*--------------------------------------------------------------
# LAYOUT
--------------------------------------------------------------*/
.main-page {
  width: 100%;
}

.main-page a:not(.btn) {
  color: inherit;
}

.page-title-div {
  background: #fff;
  padding: 15px;
}

.page-title-div .title {
  margin-top: 10px;
}

.page-title-div .sub-title {
  color: #767676;
  margin-bottom: 0;
}

.page-title-div .right-side {
  text-align: right;
}

.page-title-div .right-side .btn {
  margin-top: 12px;
}

.page-title-div.dark-title {
  background: #292929;
}

.page-title-div.dark-title .title {
  color: #fff !important;
}

.breadcrumb-div {
  background-color: #fff;
  padding: 8px 15px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  font-size: 90%;
  color: #767676;
}

.breadcrumb-div a {
  color: inherit;
}

.breadcrumb-div a:hover {
  color: #3498db !important;
}

.breadcrumb-div .breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0;
}

.breadcrumb-div .breadcrumb .fa {
  margin-right: 5px;
}

.breadcrumb-div .text-right a .fa {
  margin-right: 2px;
}

.content-internal {
  margin-right: -280px;
  width: 100%;
  float: left;
}

.content-internal .content {
  margin-right: 280px;
  background: #fff;
  padding: 15px;
}

.sidebar-internal {
  float: right;
}

.sidebar-internal.affix {
  right: 15px;
  top: 80px;
  position: fixed !important;
}

.sidebar-internal .sidebar {
  width: 260px;
  background: #fff;
  padding: 15px;
}

/*--------------------------------------------------------------
# PANELS
--------------------------------------------------------------*/
.panel {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.panel .panel-title h1, .panel .panel-title h2, .panel .panel-title h3, .panel .panel-title h4, .panel .panel-title h5, .panel .panel-title h6 {
  color: inherit !important;
  margin: 0;
  line-height: 30px;
}

.panel-heading {
  padding: 5px;
}

.panel-heading .panel-title {
  margin-top: 10px;
  padding-left: 15px;
}

.panel-heading .panel-title small {
  color: #767676;
  font-size: 75%;
  font-style: italic;
  margin-left: 5px;
}

.lobipanel {
  margin-bottom: 20px;
}

.lobipanel > .panel-heading > .panel-title h1, .lobipanel > .panel-heading > .panel-title h2, .lobipanel > .panel-heading > .panel-title h3, .lobipanel > .panel-heading > .panel-title h4, .lobipanel > .panel-heading > .panel-title h5, .lobipanel > .panel-heading > .panel-title h6 {
  line-height: inherit;
  margin-top: 0px;
}

.lobipanel > .panel-heading .dropdown .dropdown-toggle .panel-control-icon {
  line-height: inherit;
  margin-top: 10px;
}

.lobipanel .panel-heading .dropdown .dropdown-menu > li > a {
  font-size: 12px;
}

.lobipanel .panel-heading .dropdown .dropdown-menu > li > a:focus:hover, .lobipanel .panel-heading .dropdown .dropdown-menu > li > a:hover {
  text-shadow: none;
  background-color: rgba(0, 0, 0, 0.1);
}

.lobipanel > .panel-heading > .panel-title input {
  background-color: rgba(0, 0, 0, 0.1);
  border: 0;
}

.lobipanel > .panel-heading > .panel-title {
  margin-top: 10px;
}

.lobipanel.panel-expanded {
  border-radius: 0;
}

.lobipanel.panel-unpin {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.lobipanel > .panel-heading {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.panel-default {
  border-color: #e5e5e5;
}

.panel-default .panel-heading {
  color: #292929;
  background-color: #f2f2f2;
  border-color: #e5e5e5;
}

.panel-default .panel-heading small {
  color: #292929;
}

.panel-primary {
  border-color: #258cd1;
}

.panel-primary .panel-heading {
  color: #fff;
  background-color: #3498db;
  border-color: #258cd1;
}

.panel-primary .panel-heading small {
  color: #fff;
}

.panel-danger {
  border-color: #e43725;
}

.panel-danger .panel-heading {
  color: #fff;
  background-color: #e74c3c;
  border-color: #e43725;
}

.panel-danger .panel-heading small {
  color: #fff;
}

.panel-success {
  border-color: #229955;
}

.panel-success .panel-heading {
  color: #fff;
  background-color: #27ae60;
  border-color: #229955;
}

.panel-success .panel-heading small {
  color: #fff;
}

.panel-warning {
  border-color: #e08e0b;
}

.panel-warning .panel-heading {
  color: #fff;
  background-color: #f39c12;
  border-color: #e08e0b;
}

.panel-warning .panel-heading small {
  color: #fff;
}

.panel-info {
  border-color: #46b8da;
}

.panel-info .panel-heading {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.panel-info .panel-heading small {
  color: #fff;
}

.panel-black {
  border-color: #1c1c1c;
}

.panel-black .panel-heading {
  color: #fff;
  background-color: #292929;
  border-color: #1c1c1c;
}

.panel-black .panel-heading small {
  color: #fff;
}

.panel-light-black {
  border-color: #3c3c3c;
}

.panel-light-black .panel-heading {
  color: #fff;
  background-color: #494949;
  border-color: #3c3c3c;
}

.panel-light-black .panel-heading small {
  color: #fff;
}

.acc-panels .panel-heading .panel-title {
  margin-top: 5px;
}

.acc-panels .panel-heading .panel-title a {
  display: block;
}

.acc-panels .panel-heading .panel-title a .icon-plus {
  margin-right: 5px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.acc-panels .panel-heading .panel-title a .icon-plus-right {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.acc-panels .panel-heading .panel-title a[aria-expanded=true] .icon-plus {
  margin-right: 5px;
  transform: rotate(135deg);
}

.acc-panels .panel-heading .panel-title a[aria-expanded=true] .icon-plus-right {
  transform: rotate(135deg);
}

/*--------------------------------------------------------------
# TABS
--------------------------------------------------------------*/
.nav-tabs.right-aligned li {
  float: right;
}

.nav-tabs .dropdown-menu > .active > a, .nav-tabs .dropdown-menu > .active > a:hover, .nav-tabs .dropdown-menu > .active > a:focus {
  color: #fff;
}

.nav-tabs > li > a {
  opacity: 0.4;
}

.nav-tabs > li.active > a {
  opacity: 1;
}

.nav-tabs.border-bottom > li.active > a, .nav-tabs.border-bottom > li.active > a:hover, .nav-tabs.border-bottom > li.active > a:focus {
  border: 0 !important;
  border-bottom: 4px solid #ddd !important;
}

.nav-tabs.border-bottom > li > a {
  border: 0 !important;
}

.nav-tabs.border-bottom.border-primary > li.active > a, .nav-tabs.border-bottom.border-primary > li.active > a:hover, .nav-tabs.border-bottom.border-primary > li.active > a:focus {
  border-bottom: 4px solid #3498db !important;
}

.nav-tabs.border-bottom.border-danger > li.active > a, .nav-tabs.border-bottom.border-danger > li.active > a:hover, .nav-tabs.border-bottom.border-danger > li.active > a:focus {
  border-bottom: 4px solid #e74c3c !important;
}

.nav-tabs.border-bottom.border-success > li.active > a, .nav-tabs.border-bottom.border-success > li.active > a:hover, .nav-tabs.border-bottom.border-success > li.active > a:focus {
  border-bottom: 4px solid #27ae60 !important;
}

.nav-tabs.border-bottom.border-warning > li.active > a, .nav-tabs.border-bottom.border-warning > li.active > a:hover, .nav-tabs.border-bottom.border-warning > li.active > a:focus {
  border-bottom: 4px solid #f39c12 !important;
}

.nav-tabs.border-bottom.border-black > li.active > a, .nav-tabs.border-bottom.border-black > li.active > a:hover, .nav-tabs.border-bottom.border-black > li.active > a:focus {
  border-bottom: 4px solid #292929 !important;
}

.nav-tabs.border-bottom.border-gray > li.active > a, .nav-tabs.border-bottom.border-gray > li.active > a:hover, .nav-tabs.border-bottom.border-gray > li.active > a:focus {
  border-bottom: 4px solid #f2f2f2 !important;
}

.tabs-left .nav-tabs {
  float: left;
  border-bottom: 0;
}

.tabs-left .nav-tabs li {
  float: none;
  margin: 0;
}

.tabs-left .nav-tabs li a {
  margin-right: 0;
  border: 0;
  background-color: #292929;
  color: #fff;
  border-radius: 0;
  opacity: 1;
}

.tabs-left .nav-tabs li a:hover {
  background-color: #494949;
}

.tabs-left .tab-content {
  margin-left: 45px;
}

.tabs-left .tab-content .tab-pane {
  display: none;
  background-color: #f2f2f2;
  padding: 15px;
  overflow-y: auto;
}

.tabs-left .tab-content .active {
  display: block;
}

.tabs-left .nav-tabs > li.active > a, .tabs-left .nav-tabs > li.active > a:hover, .tabs-left .nav-tabs > li.active > a:focus {
  border: 0;
  background: #f2f2f2;
  color: #292929;
}

.tabs-right .nav-tabs {
  float: right;
  border-bottom: 0;
}

.tabs-right .nav-tabs li {
  float: none;
  margin: 0;
}

.tabs-right .nav-tabs li a {
  margin-left: 0;
  border: 0;
  background-color: #292929;
  color: #fff;
  border-radius: 0;
  opacity: 1;
}

.tabs-right .nav-tabs li a:hover {
  background-color: #494949;
}

.tabs-right .tab-content {
  margin-right: 45px;
}

.tabs-right .tab-content .tab-pane {
  display: none;
  background-color: #f2f2f2;
  padding: 15px;
  overflow-y: auto;
}

.tabs-right .tab-content .active {
  display: block;
}

.tabs-right .nav-tabs > li.active > a, .tabs-right .nav-tabs > li.active > a:hover, .tabs-right .nav-tabs > li.active > a:focus {
  border: 0;
  background: #f2f2f2;
  color: #292929;
}

/*--------------------------------------------------------------
# LABELS & BADGES
--------------------------------------------------------------*/
.label {
  padding: 0.2em 0.6em;
}

.label-rounded {
  padding: .2em 1.2em .3em;
  border-radius: 20px;
}

.label-flat {
  border-radius: 0;
}

.label-wide {
  padding: .2em 1.2em .3em;
}

.label-bordered {
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
}

.label-bordered.label-default {
  border-color: #777777;
  color: #777777 !important;
}

.label-bordered.label-primary {
  border-color: #3498db;
  color: #3498db !important;
}

.label-bordered.label-danger {
  border-color: #e74c3c;
  color: #e74c3c !important;
}

.label-bordered.label-success {
  border-color: #27ae60;
  color: #27ae60 !important;
}

.label-bordered.label-warning {
  border-color: #f39c12;
  color: #f39c12 !important;
}

.label-bordered.label-info {
  border-color: #5bc0de;
  color: #5bc0de !important;
}

.label-bordered.label-black {
  border-color: #292929;
  color: #292929 !important;
}

.label-bordered.label-gray {
  border-color: #f2f2f2;
  color: #f2f2f2 !important;
}

.badge.badge-default {
  background-color: #777777;
}

.badge.badge-primary {
  background-color: #3498db;
}

.badge.badge-danger {
  background-color: #e74c3c;
}

.badge.badge-success {
  background-color: #27ae60;
}

.badge.badge-warning {
  background-color: #f39c12;
}

.badge.badge-info {
  background-color: #5bc0de;
}

.badge.badge-black {
  background-color: #292929;
}

.badge.badge-gray {
  background-color: #f2f2f2;
  color: #292929;
}

.badge.badge-bordered {
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
}

.badge.badge-bordered.badge-default {
  border-color: #777777;
  color: #777777 !important;
}

.badge.badge-bordered.badge-primary {
  border-color: #3498db;
  color: #3498db !important;
}

.badge.badge-bordered.badge-danger {
  border-color: #e74c3c;
  color: #e74c3c !important;
}

.badge.badge-bordered.badge-success {
  border-color: #27ae60;
  color: #27ae60 !important;
}

.badge.badge-bordered.badge-warning {
  border-color: #f39c12;
  color: #f39c12 !important;
}

.badge.badge-bordered.badge-info {
  border-color: #5bc0de;
  color: #5bc0de !important;
}

.badge.badge-bordered.badge-black {
  border-color: #292929;
  color: #292929 !important;
}

.badge.badge-bordered.badge-gray {
  border-color: #f2f2f2;
  color: #f2f2f2 !important;
}

/*--------------------------------------------------------------
# SECTIONS
--------------------------------------------------------------*/
.section {
  padding: 30px 0;
}

.section .section-title .title {
  margin-top: 0;
}

.section .section-title .sub-title {
  color: #767676;
}

.underline {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 12px;
}

.underline:after {
  content: "";
  width: 32px;
  height: 1px;
  background: #767676;
  position: absolute;
  bottom: 0;
  left: 0;
}

.text-center .underline::after {
  width: 4%;
  left: 48%;
}

/*--------------------------------------------------------------
# BUTTONS
--------------------------------------------------------------*/
.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: 0;
}

.btn-rounded {
  border-radius: 30px;
}

.btn-wide {
  padding: 6px 22px;
}

.btn .fa {
  margin-right: 6px;
}

.btn.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}

.btn.btn-labeled .fa {
  margin-right: 0px;
}

.btn.btn-labeled .btn-label {
  position: relative;
  background: transparent;
  background: rgba(0, 0, 0, 0.15);
  display: inline-block;
  padding: 6px 12px;
  left: -12px;
  border-radius: 4px 0 0 4px;
}

.btn.btn-labeled .btn-label.btn-label-right {
  left: auto;
  right: -12px;
  border-radius: 0 4px 4px 0;
}

.btn.btn-labeled.btn-rounded .btn-label {
  border-radius: 30px 0 0 30px;
}

.btn.btn-labeled.btn-rounded .btn-label.btn-label-right {
  left: auto;
  right: -12px;
  border-radius: 0 30px 30px 0;
}

.btn.icon-only .fa {
  margin-right: 0;
}

.btn.btn-animated {
  position: relative;
  overflow: hidden;
}

.btn.btn-animated .hidden-content {
  position: absolute;
  width: 100%;
  right: -100%;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn.btn-animated .visible-content {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn.btn-animated:hover .hidden-content {
  right: 0;
}

.btn.btn-animated:hover .visible-content {
  opacity: 0;
}

.btn.btn-lg.btn-labeled .btn-label {
  padding: 10px 16px;
  left: -16px;
}

.btn.btn-lg.btn-labeled .btn-label.btn-label-right {
  left: auto;
  right: -16px;
}

.btn.btn-sm.btn-labeled .btn-label {
  padding: 5px 10px;
  left: -10px;
}

.btn.btn-sm.btn-labeled .btn-label.btn-label-right {
  left: auto;
  right: -10px;
}

.btn.btn-xs.btn-labeled .btn-label {
  padding: 1px 5px;
  left: -5px;
}

.btn.btn-xs.btn-labeled .btn-label.btn-label-right {
  left: auto;
  right: -5px;
}

/*--------------------------------------------------------------
# MODALS
--------------------------------------------------------------*/
.modal-title small {
  color: #767676;
  font-size: 75%;
  font-style: italic;
  margin-left: 5px;
}

.modal-color-primary .modal-backdrop {
  background-color: #3498db;
}

.modal-color-danger .modal-backdrop {
  background-color: #e74c3c;
}

.modal-color-success .modal-backdrop {
  background-color: #27ae60;
}

.modal-color-black .modal-backdrop {
  background-color: #292929;
}

.modal-color-warning .modal-backdrop {
  background-color: #f39c12;
}

.modal-color-gray .modal-backdrop {
  background-color: #f2f2f2;
}

.modal-color-white .modal-backdrop {
  background-color: #fff;
}

.modal-color-info .modal-backdrop {
  background-color: #5bc0de;
}

.trans-modal .modal-content {
  background-color: transparent;
  box-shadow: none;
  border: 0;
  color: #fff;
}

.trans-modal .modal-content .close {
  color: #fff;
}

.trans-modal .modal-content h1, .trans-modal .modal-content h2, .trans-modal .modal-content h3, .trans-modal .modal-content h4, .trans-modal .modal-content h5, .trans-modal .modal-content h6, .trans-modal .modal-content .h1, .trans-modal .modal-content .h2, .trans-modal .modal-content .h3, .trans-modal .modal-content .h4, .trans-modal .modal-content .h5, .trans-modal .modal-content .h6 {
  color: #fff;
}

.trans-modal .modal-content .modal-header {
  border: 0px;
}

.trans-modal .modal-content .modal-footer {
  border: 0px;
}

.modal-footer.text-center {
  text-align: center !important;
}

.modal-title .fa {
  margin-right: 5px;
}

.modal.vert-center {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal.vert-center:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal.vert-center .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal.vert-top {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal.vert-top:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal.vert-top .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  margin-top: 0;
}

.modal.vert-top .modal-content {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0px;
}

.modal.vert-bottom {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal.vert-bottom:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal.vert-bottom .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: bottom;
  margin-bottom: 0;
}

.modal.vert-bottom .modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0px;
}

.modal.modal-full-screen .modal-dialog {
  width: 100%;
  height: 100%;
  margin-top: 0;
}

.modal.modal-full-screen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.iziModal .iziModal-header-subtitle, .iziModal .iziModal-header-title {
  font-family: "Poppins", sans-serif;
  clear: none;
}

.iziModal-wrap {
  /* padding: 15px; */
}

.iziModal .iziModal-header {
  background: #3498db;
}

.iziModal .iziModal-header .fa {
  font-size: 30px;
}

.sweet-alert {
  font-family: "Poppins", sans-serif;
}

.sweet-alert h2 {
  font-family: "Poppins", sans-serif;
}

.sweet-alert input {
  display: none;
}

.sweet-alert fieldset {
  padding: 0;
}

/*--------------------------------------------------------------
# DASHBOARD STATS
--------------------------------------------------------------*/
.dashboard-stat {
  display: block;
  padding: 30px 15px;
  text-align: right;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.dashboard-stat .number {
  font-size: 28px;
  display: block;
}

.dashboard-stat .bg-icon {
  position: absolute;
  font-size: 80px;
  opacity: 0.4;
  left: 0;
  bottom: 0;
}

.dashboard-stat:hover {
  background: #292929 !important;
}

.dashboard-stat-2 {
  display: block;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.dashboard-stat-2 .stat-content {
  padding: 20px 15px 15px;
  text-align: center;
  position: relative;
}

.dashboard-stat-2 .number {
  font-size: 28px;
  display: block;
}

.dashboard-stat-2 .stat-footer {
  background: #fff;
  color: #292929;
  text-align: center;
  display: block;
  padding: 8px;
  font-size: 90%;
}

.dashboard-stat-2:hover {
  background: #292929 !important;
}

@media (max-width: 768px) {
  .dashboard-stat {
    margin-bottom: 10px;
  }
  .dashboard-stat-2 {
    margin-bottom: 10px;
  }
}

/*--------------------------------------------------------------
# NOTIFICATIONS
--------------------------------------------------------------*/
.toast-success {
  background-color: #27ae60;
}

.toast-error {
  background-color: #e74c3c;
}

.toast-warning {
  background-color: #f39c12;
}

.toast-info {
  background-color: #5bc0de;
}

#toast-container > div {
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  opacity: 0.95;
}

#toast-container > div:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.ui-pnotify .alert-warning {
  background-color: #f39c12;
  border-color: #f39c12;
  color: #fff;
}

.ui-pnotify .alert-info {
  background-color: #5bc0de;
  border-color: #5bc0de;
  color: #fff;
}

.ui-pnotify .alert-success {
  background-color: #27ae60;
  border-color: #27ae60;
  color: #fff;
}

.ui-pnotify .alert-danger {
  background-color: #e74c3c;
  border-color: #e74c3c;
  color: #fff;
}

.ui-pnotify .alert h4 {
  clear: none;
}

.ui-pnotify .ui-pnotify-icon, .ui-pnotify .ui-pnotify-icon span {
  margin-top: 5px;
  margin-right: 5px;
}

.ui-pnotify .ui-pnotify-icon.false, .ui-pnotify .ui-pnotify-icon span.false {
  margin: 0;
}

.ui-pnotify .ui-pnotify-closer, .ui-pnotify .ui-pnotify-sticker {
  margin-top: 5px;
  margin-left: 5px;
}

.ui-pnotify .ui-pnotify-closer:focus, .ui-pnotify .ui-pnotify-sticker:focus {
  outline: 0;
}

.ui-pnotify.alert-left-icon .alert-info {
  border-color: #28a1c5;
}

.ui-pnotify.alert-left-icon .alert-warning {
  border-color: #b06f09;
}

.ui-pnotify.alert-left-icon .alert-success {
  border-color: #19703e;
}

.ui-pnotify.alert-left-icon .alert-danger {
  border-color: #bf2718;
}

.ui-pnotify.alert-left-icon .ui-pnotify-container {
  border-left-width: 50px;
}

.ui-pnotify.alert-left-icon:after {
  left: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f071";
  text-align: center;
  font-size: 18px;
  margin-top: -9px;
  line-height: 1;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-pnotify.alert-right-icon .alert-info {
  border-color: #28a1c5;
}

.ui-pnotify.alert-right-icon .alert-warning {
  border-color: #b06f09;
}

.ui-pnotify.alert-right-icon .alert-success {
  border-color: #19703e;
}

.ui-pnotify.alert-right-icon .alert-danger {
  border-color: #bf2718;
}

.ui-pnotify.alert-right-icon .ui-pnotify-container {
  border-right-width: 50px;
}

.ui-pnotify.alert-right-icon:after {
  right: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f071";
  text-align: center;
  font-size: 18px;
  margin-top: -9px;
  line-height: 1;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-pnotify .ui-pnotify-shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.left-icon-alert {
  border: 1px solid #f2f2f2;
  border-left-width: 50px;
  position: relative;
}

.left-icon-alert:after {
  left: -50px;
  position: absolute;
  top: 50%;
  width: 50px;
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f071";
  text-align: center;
  font-size: 18px;
  margin-top: -9px;
  line-height: 1;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.left-icon-alert.alert-info {
  border-color: #28a1c5 !important;
}

.left-icon-alert.alert-info:after {
  content: "\f129";
}

.left-icon-alert.alert-warning {
  border-color: #b06f09 !important;
}

.left-icon-alert.alert-warning:after {
  content: "\f129";
}

.left-icon-alert.alert-success {
  border-color: #19703e !important;
}

.left-icon-alert.alert-success:after {
  content: "\f00c";
}

.left-icon-alert.alert-danger {
  border-color: #bf2718 !important;
}

.left-icon-alert.alert-danger:after {
  content: "\f00d";
}

.right-icon-alert {
  border: 1px solid #f2f2f2;
  border-right-width: 50px;
  position: relative;
}

.right-icon-alert:after {
  right: -50px;
  position: absolute;
  top: 50%;
  width: 50px;
  font: normal normal normal 14px/1 FontAwesome;
  content: "\f071";
  text-align: center;
  font-size: 18px;
  margin-top: -9px;
  line-height: 1;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.right-icon-alert.alert-info {
  border-color: #28a1c5 !important;
}

.right-icon-alert.alert-info:after {
  content: "\f129";
}

.right-icon-alert.alert-warning {
  border-color: #b06f09 !important;
}

.right-icon-alert.alert-warning:after {
  content: "\f129";
}

.right-icon-alert.alert-success {
  border-color: #19703e !important;
}

.right-icon-alert.alert-success:after {
  content: "\f00c";
}

.right-icon-alert.alert-danger {
  border-color: #bf2718 !important;
}

.right-icon-alert.alert-danger:after {
  content: "\f00d";
}

/*--------------------------------------------------------------
# PAGINATION
--------------------------------------------------------------*/
.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  color: #fff !important;
}

.pagination.rounded-corners li:first-child > a, .pagination.rounded-corners li:first-child > span {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.pagination.rounded-corners li:last-child > a, .pagination.rounded-corners li:last-child > span {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.pagination.borderless li > a, .pagination.borderless li > span {
  border: 0px;
}

.pager .disabled > a, .pager .disabled > a:hover, .pager .disabled > a:focus, .pager .disabled > span {
  opacity: 0.5;
}

/*--------------------------------------------------------------
# FORMS
--------------------------------------------------------------*/
.form-group.left-icon {
  position: relative;
}

.form-group.left-icon .fa {
  line-height: 34px;
}

.form-group.left-icon .form-control {
  padding-left: 42.5px;
}

.form-group.left-icon .form-left-icon {
  position: absolute;
  left: 15px;
  top: 25px;
}

.form-group.left-icon .form-left-icon.icon-lg {
  top: 30px;
}

.form-group.left-icon .form-left-icon.icon-sm {
  top: 22px;
}

.form-group.has-feedback .fa {
  line-height: 34px;
}

.form-group input:focus, .form-group textarea:focus, .form-group select:focus {
  box-shadow: none;
  border-color: #292929;
}

.form-horizontal .form-group.left-icon {
  position: relative;
}

.form-horizontal .form-group.left-icon .fa, .form-horizontal .form-group.left-icon .glyphicon {
  line-height: 34px;
}

.form-horizontal .form-group.left-icon .form-control {
  padding-left: 42.5px;
}

.form-horizontal .form-group.left-icon .form-left-icon {
  position: absolute;
  left: 30px;
  top: 0px;
}

.form-horizontal .form-group.left-icon .form-left-icon.icon-lg {
  top: 5px;
}

.form-horizontal .form-group.left-icon .form-left-icon.icon-sm {
  top: 0px;
}

.form-group label {
  font-size: 95%;
}

.form-group .help-block {
  font-size: 84%;
  font-style: italic;
  margin-top: -5px;
}

.input-group {
  font-size: 14px;
  margin-bottom: 0.546875em;
}

.form-horizontal .control-label.text-left {
  text-align: left;
}

.radio-label {
  display: inline-block;
}

.select2-container .select2-selection--single {
  height: 34px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 34px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 34px;
}

.select2-results {
  font-size: 90%;
}

.select2-container--default .select2-search--inline .select2-search__field {
  margin-top: 0;
  margin-bottom: 0;
}

div.tagsinput span.tag {
  border-width: 0;
  background: #4aa3df;
  color: #16527a;
  padding: 2px 10px;
}

div.tagsinput span.tag a {
  color: #196090;
}

.form-link {
  display: inline-block;
  margin-top: 15px;
  color: #767676;
}

.op-check.checkbox label, .op-check.radio label {
  padding-left: 0;
}

/*--------------------------------------------------------------
# ERROR PAGES
--------------------------------------------------------------*/
.error-box {
  text-align: center;
}

.error-box .error-icon {
  font-size: 40px;
  color: #e74c3c;
}

.error-box .error-title {
  margin-top: 10px;
  font-size: 60px;
}

.error-box .sub-title {
  font-size: 18px;
  color: #767676;
}

/*--------------------------------------------------------------
# PRICING
--------------------------------------------------------------*/
.pricing-box {
  background: #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.pricing-box .pricing-head {
  background-color: #f2f2f2;
  text-align: center;
  border-top: 3px solid #3498db;
  padding: 1.618em;
  position: relative;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
}

.pricing-box .pricing-head h1, .pricing-box .pricing-head h2, .pricing-box .pricing-head h3, .pricing-box .pricing-head h4, .pricing-box .pricing-head h5, .pricing-box .pricing-head h6 {
  margin: 0;
}

.pricing-box .pricing-head .striked {
  text-decoration: line-through;
}

.pricing-box .pricing-head .bg-icon {
  position: absolute;
  bottom: -20px;
  left: -20px;
  font-size: 120px;
  color: #999999;
  opacity: 0.2;
}

.pricing-box .pricing-body {
  padding: 1em;
}

.pricing-box .pricing-body ul li {
  line-height: 2.25;
}

.pricing-box .pricing-body ul li .icon {
  float: right;
}

.pricing-box .pricing-foot a {
  display: block;
  padding: 1em;
  background: #f2f2f2;
  color: #292929;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 0 0 4px 4px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.pricing-box .pricing-foot a:hover {
  background: #3498db;
  color: #fff !important;
}

.pricing-box.popular .pricing-head {
  background: #494949;
  color: #fff;
}

.pricing-box.popular .pricing-head h1, .pricing-box.popular .pricing-head h2, .pricing-box.popular .pricing-head h3, .pricing-box.popular .pricing-head h4, .pricing-box.popular .pricing-head h5, .pricing-box.popular .pricing-head h6 {
  color: #fff;
}

/*--------------------------------------------------------------
# LOGIN
--------------------------------------------------------------*/
.login-bg {
  background-image: url("../images/photo-2.jpg");
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

.login-bg .login-box {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  margin-top: 150px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.login-bg-color {
  min-height: 100vh;
}

.login-bg-color .login-box {
  color: #292929 !important;
  padding: 20px;
  margin-top: 125px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.login-bg-color .login-box h1, .login-bg-color .login-box h2, .login-bg-color .login-box h3, .login-bg-color .login-box h4, .login-bg-color .login-box h5, .login-bg-color .login-box h6 {
  color: #292929 !important;
}

/*--------------------------------------------------------------
# DROPZONE
--------------------------------------------------------------*/
.dropzone {
  background: #f2f2f2;
  border: 2px dashed #d9d9d9;
  min-height: 250px;
}

.dropzone .dz-message {
  font-size: 16px;
  font-weight: bold;
  margin: 4.5em 0;
}

.dropzone .dz-message .note {
  font-size: 13px;
  font-weight: normal;
  color: #494949;
  margin-top: 15px;
}

/*--------------------------------------------------------------
# BOOTSTRAP SWITCH
--------------------------------------------------------------*/
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary, .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
  background: #3498db;
}

/*--------------------------------------------------------------
# JQUERY STEPS
--------------------------------------------------------------*/
.wizard > .steps .current a, .wizard > .steps .current a:hover, .wizard > .steps .current a:active {
  background: #3498db;
}

.wizard > .actions a, .wizard > .actions a:hover, .wizard > .actions a:active {
  background: #3498db;
  color: #fff;
}

.wizard > .content {
  min-height: 250px;
}

.wizard > .content > .body label.error {
  color: #e74c3c;
  font-size: 90%;
  font-weight: normal;
}

/*--------------------------------------------------------------
# DATATABLES
--------------------------------------------------------------*/
.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  background-color: #3498db;
  border-color: #3498db;
}

div.dataTables_wrapper div.dataTables_filter label {
  text-align: right;
}

/*--------------------------------------------------------------
# MISCELLANEOUS
--------------------------------------------------------------*/
pre[class*="language-"] {
  border-left-width: 2px;
}

.src-btn {
  float: right;
}

.draggable-handle {
  cursor: move;
}

.panel-heading .dropdown .fa.dropdown-toggle {
  padding-right: 15px;
  padding-top: 15px;
}

.media-object {
  margin-top: 10px;
}

.media-bottom {
  padding-bottom: 1.75em;
}

.op-chart {
  width: 100%;
  height: 500px;
}

[class^="icheckbox_line"].checked {
  opacity: 0.6;
}

.browser {
  border: 2px solid #d9d9d9;
  border-top-width: 30px;
  width: 60%;
  margin: auto;
  border-radius: 6px;
}

.browser iframe {
  border: 0;
  width: 100%;
}

.popover.confirmation {
  max-width: 100%;
}

.dropdown .dropdown-toggle {
  cursor: pointer;
}

.dropdown .icon-right {
  margin-left: 15px;
  float: right;
  margin-top: 3px;
  margin-right: 0 !important;
}

.show-grid [class*="col-"] {
  border: 1px solid #cccccc;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #d9d9d9;
}

.ecomm-action-icon:after {
  display: none !important;
}

.table-dropdown .btn-default {
  color: #333;
  background-color: transparent;
  border-color: transparent;
}

.table-dropdown .open > .btn-default.dropdown-toggle {
  color: #333;
  background-color: transparent !important;
  border-color: transparent !important;
}

.table-dropdown .btn-default:active:hover {
  color: #333;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.table-clean > tbody > tr > td {
  padding: 8px;
  line-height: 1.428571429;
  vertical-align: top;
  border-top: none !important;
}

tspan.highcharts-text-outline {
  stroke-width: 0 !important;
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  padding: 4px;
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
}

.highcharts-title {
  font-size: 14px !important;
  font-family: "Poppins", sans-serif !important;
  color: #292929 !important;
}
